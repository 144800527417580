.ant-layout-sider {
    background: #fff !important;
  }

  .LeftSider {
    border-right: 1px solid rgba(0,0,0,0.1) !important;
  }

  .RightSider {
    border-left: 1px solid rgba(0,0,0,0.1) !important;
  }

.ant-layout-sider .ant-layout-sider-children .ant-menu {
    border-inline-end: 0;
  }

