.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0; /* Adjust padding as needed */
    height: 64px;
    z-index: 1;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

.leftSection {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
}

.logo {
    color: black;
    font-weight: bold;
    margin-right: 20px; /* Adjust spacing between logo and search input as needed */
    /* Ensure the logo takes only the space it needs */
    white-space: nowrap;
}

/* Make the search input flexible to take up remaining space */
.searchInput {
    flex-grow: 1;
    color: rgba(0,0,0,0.45);
}

/* Adjust the Menu component's style as needed */
.ant-menu-horizontal {
    display: flex;
    justify-content: flex-end;
    width: 40%;
    border-bottom: none; /* Removes the default underline of the horizontal menu */
}

/* Additional styles for the Menu items if needed */
.ant-menu-item {
    align-self: center;
}