.chatContainer {
    display: flex;
    flex-direction: column;
    height: 100%; /* Fill the height of the browser */
}

.messagesContainer {
    flex: 1;
    overflow-y: auto; /* Make it scrollable */
    padding: 16px;
}

.inputContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.message-avatar {
    flex: 0 0 50px; /* Set avatar width to 50px and prevent it from growing or shrinking */
    margin-right: 0px; /* Add some space between the avatar and the message */
  }

.message-content {
    flex: 1; /* Allow message content to take up the remaining space */
}

.message {
    display: flex;
    align-items: center; /* Align items vertically */
    margin-bottom: 10px; /* Add some space between messages */
}

@keyframes strobe-red {
    0%, 100% { background-color: transparent; }
    50% { background-color: rgba(255, 0, 0, 0.20); } /* Red with 50% alpha */
  }
  
@keyframes strobe-green {
0%, 100% { background-color: transparent; }
50% { background-color: rgba(0, 255, 0, 0.20); } /* Green with 50% alpha */
}

.strobe-red {
    animation: strobe-red 1s infinite;
}

.strobe-green {
    animation: strobe-green 1s infinite;
}

.custom-tooltip .ant-tooltip-inner {
    background-color: #fff !important; /* Light grey background */
    color: #000 !important; /* Dark text color for contrast */
    padding: 0px !important;
}

.custom-tooltip .ant-tooltip-content {
    width: 400px !important; /* Set the width of the tooltip */
}